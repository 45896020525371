import { IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/ComboBox';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { TextField } from 'office-ui-fabric-react/lib/components/TextField';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { onlyFinanceRolesType } from '../../../common/const/rolesAssignments';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { SearchPeople } from '../../../common/search/searchPeople';
import {
	BaseName,
	BaseType,
	CompanyBase,
	Country,
	InputSelectModel,
	Language,
	NotaryPeople,
	Person,
	RepresentativeCollection,
	RepresentativeDetail,
	SearchSelectModel,
	UserState,
	createEmptyRepresentativeDetail
} from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { RepresentativeFilter, createEmptyRepresentativeFilter } from '../../../model/filters/RepresentativeFilter';
import { businessTypeEnum } from '../../../utils/enums/businessTypeEnum';
import { PowerExerciseEnum } from '../../../utils/enums/powerExerciseEnum';
import { PowerTypeEnum } from '../../../utils/enums/powerTypeEnum';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';

export interface IProps {
	getRepresentatives: (filter: RepresentativeFilter, pageNumbers: number, itemsPage: number) => Promise<RepresentativeCollection>;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	getNotaryPeopleNames: (filter: string) => void;
	getCompanies: (tradeId?: number) => Promise<BaseName[]>;
	filter: RepresentativeFilter;
	powers: BaseType[];
	language: Language;
	companies: CompanyBase[];
	tradeCompanies: CompanyBase[];
	departments: BaseType[];
	countries: Country[];
	powerTypeNames: BaseType[];
	powerExerciseNames: BaseType[];
	people: Person[];
	onchangeFilter: any;
	representativeUsers: Person[];
	business: BaseType[];
	itemsPage: number;
	user: UserState;
	notaryPeopleNames: NotaryPeople[];
}

export interface IState {
	filter: RepresentativeFilter;
	selectedRepresentative: any;
	exerciseOptions: IChoiceGroupOption[];
	collapsed: boolean;
	representative: RepresentativeDetail;
	selectedPerson: any;
	isPowerTypeDisabled: boolean;
	isCountryDisabled: boolean;
	selectedNotaryPeople: any;
	sortedCompanies: IComboBoxOption[];
	showDissolved: boolean;
}

export class RepresentativeSearchComponent extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyRepresentativeFilter(),
			selectedRepresentative: null,
			exerciseOptions: [],
			representative: createEmptyRepresentativeDetail(),
			selectedPerson: null,
			isPowerTypeDisabled: false,
			isCountryDisabled: false,
			selectedNotaryPeople: null,
			sortedCompanies: [],
			showDissolved: true,
		};
	}

	componentDidMount() {
		let selectedPerson = null;
		if (this.props.filter.RepresentativeId > 0) {
			selectedPerson = { value: this.props.filter.RepresentativeId, label: this.props.filter.RepresentativeName };
		}

		let selectedNotaryPeople = null;
		if (this.props.filter.NotaryPeopleId > 0) {
			selectedNotaryPeople = { value: this.props.filter.NotaryPeopleId, label: this.props.filter.NotaryPeopleName };
		}
		this.setState({ filter: this.props.filter, selectedPerson: selectedPerson, selectedNotaryPeople: selectedNotaryPeople });
	}

	componentDidUpdate(prevProps) {
		if (this.props.powerTypeNames !== prevProps.powerTypeNames) {
			let isPowerTypeDisabled = false;
			if (utils.isInRoles(onlyFinanceRolesType)) {
				let financial = this.props.powerTypeNames.find((x) => x.descriptionEnglish === 'Financial')?.id;
				if (financial !== undefined) {
					let filter = { ...this.state.filter };
					filter.PowerId = financial;
					isPowerTypeDisabled = true;
					filter.PowerTypeName = this.props.language.name.includes(ActiveLanguage.English)
						? this.props.powerTypeNames.find((x) => x.id === filter.PowerId)?.descriptionEnglish
						: this.props.powerTypeNames.find((x) => x.id === filter.PowerId)?.descriptionSpanish;
					this.setState({ filter: filter, isPowerTypeDisabled: isPowerTypeDisabled });
				}
			}
		}

		if (this.props.countries !== prevProps.countries) {
			let isCountryDisabled = false;
			if (this.props.countries.length === 1) {
				let netherlands = this.props.countries.find((x) => x.descriptionEnglish === 'Netherlands')?.id;
				if (netherlands !== undefined) {
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
					filter.Country = this.props.language.name.includes(ActiveLanguage.English)
						? this.props.countries.find((x) => x.id === filter.CountryId)?.descriptionEnglish
						: this.props.countries.find((x) => x.id === filter.CountryId)?.descriptionSpanish;
					isCountryDisabled = true;
					this.setState({ filter: filter, isCountryDisabled: isCountryDisabled });
				}
			}
		}
	}

	private getTradeIdRole(): number {
		switch (this.props.user.currentUser.roles.sort()[0]) {
			case userRoles.ajAeropuertos:
			case userRoles.ajAeropuertosH:
				return businessTypeEnum.aeropuertos;
			case userRoles.ajCintra:
			case userRoles.ajCintraH:
				return businessTypeEnum.cintra;
			case userRoles.ajConstruccion:
			case userRoles.ajConstruccionH:
				return businessTypeEnum.construccion;
			case userRoles.ajCorporacion:
			case userRoles.ajCorporacionH:
				return businessTypeEnum.corporacion;
			case userRoles.ajFeim:
			case userRoles.ajFeimH:
				return businessTypeEnum.feim;
			case userRoles.admin:
				return 0;
		}
	}

	public render() {
		let itemsPeople: InputSelectModel[] = [];
		this.props.people.forEach((item) => {
			itemsPeople.push({ id: item.id, value: item.name });
		});

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}	
		
		let companies: IComboBoxOption[] = [];
		companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		if(this.state.filter.TradeId <= 0){
			this.props.companies.forEach((item) => {
				companies.push({ key: item.id, text: item.name, id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString():null, title: item.fechaLiquidacion? new Date(item.fechaLiquidacion).toLocaleDateString(): null });
			});
		}
		else{
			this.props.tradeCompanies.forEach((item) => {
				companies.push({ key: item.id, text: item.name, id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString():null, title: item.fechaLiquidacion? new Date(item.fechaLiquidacion).toLocaleDateString(): null });
			});
		}
		

		let departments: IComboBoxOption[] = [];		
		this.props.departments.forEach((item) => {
			if (this.props.language.name.includes(ActiveLanguage.English)) {
				departments.push({ key: item.id, text: item.descriptionEnglish });
			} else {
				departments.push({ key: item.id, text: item.descriptionSpanish });
			}
		});
		departments = departments.sort((a, b) => a.text.localeCompare(b.text));
		departments.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		departments.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let users: SearchSelectModel[] = [];
		if (this.props.representativeUsers && this.props.representativeUsers.length > 0) {
			this.props.representativeUsers.forEach((item) => {
				users.push({ value: item.id, label: `${item.name} ${item.email ? '(' + item.email + ')' : ''}` });
			});
		}

		let states: IComboBoxOption[] = [];
		states.push(
			{ key: -1, text: language['generic']['form']['combo-box']['default'] },
			{ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
			{ key: 1, text: language['representatives']['list']['filter']['options']['actionType']['options']['revoke'] },
			{ key: 2, text: language['representatives']['list']['filter']['options']['actionType']['options']['consent'] },
		);

		let exercises: IComboBoxOption[] = [];
		exercises.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		exercises.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		this.props.powerExerciseNames
			.filter((x) => x.id === PowerExerciseEnum.solidaria || PowerExerciseEnum.mancumunada || PowerExerciseEnum.ambas)
			.forEach((item) => {
				// if (this.props.language.name.includes(ActiveLanguage.English)) {
				// 	exercises.push({ key: item.id, text: item.descriptionEnglish });
				// } else {
				// 	exercises.push({ key: item.id, text: item.descriptionSpanish });
				// }
				exercises.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});	
			});

		let countries: IComboBoxOption[] = [];
		if (this.props.countries) {			
			this.props.countries.forEach((item) => {
				countries.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			countries.sort((a, b) => a.text.localeCompare(b.text))
			countries.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			countries.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}
		

		let powerTypeNames: IComboBoxOption[] = [];
		if (this.props.powerTypeNames) {
			powerTypeNames.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
			powerTypeNames.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			this.props.powerTypeNames.forEach((item) => {
				powerTypeNames.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {			
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});	
			business = business.sort((a, b) => a.text.localeCompare(b.text));	
			business.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });	
			business.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}
		

		let statesPower: IComboBoxOption[] = [];
		statesPower.push(
			{ key: -1, text: language['generic']['form']['combo-box']['default'] },
			{ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
			{ key: 1, text: language['representatives']['list']['filter']['options']['status']['current'] },
			{ key: 2, text: language['representatives']['list']['filter']['options']['status']['notCurrent'] },
		);

		let calendarStrings: IDatePickerStrings = language['calendar'];

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 						<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);}
			
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companies.sort((a, b) => a.text.localeCompare(b.text))
		//sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'], itemType: DropdownMenuItemType.Header });
		//sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });	
		
		//let options: ISelectOption[] = [];

		let selectedCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		

		let options: ISelectOption[] = selectedCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });

		// selectedCompanies.forEach((item) => {
		// 	if(i<selectedCompanies.length){
		// 		options.push({value:item.key.toString(), label:item.text, disolutionDate:item.id, liquidationDate: item.title});
		// 		i++;
		// 	}
		// })		

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									//allowFreeform
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['representatives']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleCompanyChange}
									selectedKey={this.state.filter.CompaniesId}
									onRenderOption={onRenderOption}
									multiSelect={true}
								/>

							</div> */}
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl0 content_edit_block_filter'> */}
								<p>{language['representatives']['list']['filter']['options']['society']['title']}</p>
								<CustomSelect
									options={options}
									handleCustomSelectChange={this.handleReactSelectCompanyChange}
									showDissolved={this.state.showDissolved}
									handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
									language={language}
									showDissolutionDate={true}
									selectedValues={this.state.filter.selectedCompanies}
									isMultiSelection={true}
									isDisabled={false}
									placeholder={language['representatives']['list']['filter']['options']['society']['placeholder']}
								/>

							</div>												
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['uniqueCode']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.UniqueCode}
									onChange={this.handleUniqueCodeChange}
									onBlur={this.handleUniqueCodeBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['cif']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Cif}
									onChange={this.handleCifChange}
									onBlur={this.handleCifBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['subject']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.SubjectValue}
									onChange={this.handleSubjectChange}
									onBlur={this.handleSubjectBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<SearchPeople
									title={language['representatives']['list']['filter']['options']['representative']['title']}
									placeholder={language['representatives']['list']['filter']['options']['representative']['placeholder']}
									language={this.props.language}
									selectedOption={this.state.selectedPerson}
									loadOptions={this.loadOptions}
									handleNameChange={this.handlePersonChange}
									getUsers={this.props.getPeopleNames}
									onClearValue={this.onClearSearchPeople}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['taxYear']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={exercises}
									placeholder={language['representatives']['list']['filter']['options']['taxYear']['placeholder']}
									onChange={this.handleExerciseChange}
									selectedKey={this.state.filter.PowerExerciseId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['protocol']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Protocol}
									onChange={this.handleProtocolChange}
									onBlur={this.handleProtocolBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['inscription']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Inscription}
									onChange={this.handleInscriptionChange}
									onBlur={this.handleInscriptionBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['deed-date']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={this.state.filter.CreationDateFrom}
										maxDate={this.state.filter.CreationDateTo}
										onSelectDate={this.handleCreationDateFromChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel={language['generic']['form']['fields']['date']['to']}
										strings={calendarStrings}
										value={this.state.filter.CreationDateTo}
										minDate={this.state.filter.CreationDateFrom}
										onSelectDate={this.handleCreationDateToChange}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['deed-endDate']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel={language['generic']['form']['fields']['date']['from']}
										strings={calendarStrings}
										value={this.state.filter.RevocationDateFrom}
										maxDate={this.state.filter.RevocationDateTo}
										onSelectDate={this.handleRevocationDateFromChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel={language['generic']['form']['fields']['date']['to']}
										strings={calendarStrings}
										value={this.state.filter.RevocationDateTo}
										minDate={this.state.filter.RevocationDateFrom}
										onSelectDate={this.handleRevocationDateToChange}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['power-type']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={powerTypeNames}
									placeholder={language['representatives']['list']['filter']['options']['power-type']['placeholder']}
									onChange={this.handlePowerChange}
									selectedKey={this.state.filter.PowerId}
									disabled={this.state.isPowerTypeDisabled}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['actionType']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={states}
									placeholder={language['representatives']['list']['filter']['options']['actionType']['placeholder']}
									onChange={this.handleStateChange}
									selectedKey={this.state.filter.StateId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['status']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={statesPower}
									placeholder={language['representatives']['list']['filter']['options']['status']['placeholder']}
									onChange={this.handleStatusChange}
									selectedKey={this.state.filter.StatusPowerId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['department']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={departments}
									placeholder={language['representatives']['list']['filter']['options']['department']['placeholder']}
									onChange={this.handleDepartmentChange}
									selectedKey={this.state.filter.DepartmentId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['representatives']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={this.state.filter.TradeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['country']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={countries}
									placeholder={language['representatives']['list']['filter']['options']['country']['placeholder']}
									onChange={this.handleCountriesChange}
									selectedKey={this.state.filter.CountryId}
									disabled={this.state.isCountryDisabled}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['dni']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Dni}
									onChange={this.handleDniChange}
									onBlur={this.handleDniBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['passportOthers']}</p>
								<TextField
									type='text'
									value={this.state.filter.PassportOthers}
									onChange={this.handlePassportChange}
									onBlur={this.handlePassportBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['representatives']['list']['filter']['options']['representativeTrade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['representatives']['list']['filter']['options']['representativeTrade']['placeholder']}
									onChange={this.handleRepresentativeBusinessChange}
									selectedKey={this.state.filter.RepresentativeTradeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<SearchPeople
									title={language['representatives']['list']['filter']['options']['notaryPeople']['title']}
									placeholder={language['representatives']['list']['filter']['options']['notaryPeople']['placeholder']}
									language={this.props.language}
									selectedOption={this.state.selectedNotaryPeople}
									loadOptions={this.loadOptionsNotaryPeople}
									handleNameChange={this.handleNotaryPeopleNameChange}
									getUsers={this.props.getNotaryPeopleNames}
									onClearValue={this.onClearSearchNotaryPeople}
									showDeleteButton={true}
								/>
							</div>							
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterRepresentatives}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterRepresentatives}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.CompaniesId.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.filter.CompanyName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.filter.UniqueCode !== '' ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['uniqueCode']['title']}
								</span>
								<span className='value'>{this.state.filter.UniqueCode}</span>
							</p>
							<p style={{ display: this.state.filter.Cif !== '' ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['cif']['title']}</span>
								<span className='value'>{this.state.filter.Cif}</span>
							</p>
							<p style={{ display: this.state.filter.SubjectValue !== '' ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['subject']['title']}</span>
								<span className='value'>{this.state.filter.SubjectValue}</span>
							</p>
							<p style={{ display: this.state.filter.RepresentativeId !== 0 ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['representative']['title']}
								</span>
								<span className='value'>{this.state.filter.RepresentativeName}</span>
							</p>
							<p style={{ display: this.state.filter.PowerExerciseId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['taxYear']['title']}</span>
								<span className='value'>{this.state.filter.PowerExerciseName}</span>
							</p>
							<p style={{ display: this.state.filter.Protocol !== '' ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['protocol']['title']}</span>
								<span className='value'>{this.state.filter.Protocol}</span>
							</p>
							<p style={{ display: this.state.filter.Inscription !== '' ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['inscription']['title']}
								</span>
								<span className='value'>{this.state.filter.Inscription}</span>
							</p>
							<p
								style={{
									display:
										this.state.filter.CreationDateFrom != null || this.state.filter.CreationDateTo != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['deed-date']['title']}
								</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.filter.CreationDateFrom,
										this.state.filter.CreationDateTo,
										this.props.language,
									)}
								</span>
							</p>
							<p
								style={{
									display:
										this.state.filter.RevocationDateFrom != null || this.state.filter.RevocationDateTo != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['deed-endDate']['title']}
								</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.filter.RevocationDateFrom,
										this.state.filter.RevocationDateTo,
										this.props.language,
									)}
								</span>
							</p>
							<p style={{ display: this.state.filter.PowerId !== 0 ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['power-type']['title']}
								</span>
								<span className='value'>{this.state.filter.PowerTypeName}</span>
							</p>
							<p style={{ display: this.state.filter.StateId !== 0 ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['actionType']['title']}
								</span>
								<span className='value'>{this.state.filter.State}</span>
							</p>
							<p style={{ display: this.state.filter.StatusPower !== '' && this.state.filter.StatusPower !== null ? '' : 'none', }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['status']['title']}</span>
								<span className='value'>
									{this.state.filter.StatusPower !== '-1' ? this.state.filter.StatusPower : language['generic']['form']['combo-box']['default']}
								</span>
							</p>
							<p style={{ display: this.state.filter.DepartmentId !== 0 ? '' : 'none' }}>
								<span className='key'>
									{language['representatives']['list']['filter']['options']['department']['title']}
								</span>
								<span className='value'>{this.state.filter.Department}</span>
							</p>
							<p style={{ display: this.state.filter.TradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.filter.Trade}</span>
							</p>
							<p style={{ display: this.state.filter.CountryId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['country']['title']}</span>
								<span className='value'>{this.state.filter.Country}</span>
							</p>
							<p style={{ display: this.state.filter.Dni !== '' ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['dni']['title']}</span>
								<span className='value'>{this.state.filter.Dni}</span>
							</p>
							<p style={{ display: this.state.filter.PassportOthers !== '' ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['passportOthers']}</span>
								<span className='value'>{this.state.filter.PassportOthers}</span>
							</p>
							<p style={{ display: this.state.filter.RepresentativeTradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['representativeTrade']['title']}</span>
								<span className='value'>{this.state.filter.RepresentativeTrade}</span>
							</p>
							<p style={{ display: (this.state.filter.NotaryPeopleId != null && this.state.filter.NotaryPeopleId !== 0) ? '' : 'none' }}>
								<span className='key'>{language['representatives']['list']['filter']['options']['notaryPeople']['title']}</span>
								<span className='value'>{this.state.filter.NotaryPeopleName}</span>
							</p>							
						</div>
					</div>
				</div>
			</>
		);
	}



	private readonly onClearSearchNotaryPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedNotaryPeople = { ...this.state.selectedNotaryPeople };
		filter.NotaryPeopleId = null;
		filter.NotaryPeopleName = null;
		selectedNotaryPeople.value=null;
		selectedNotaryPeople.label=null;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });
	};

	private readonly handleNotaryPeopleNameChange = (selectedNotaryPeople) => {
		let filter = { ...this.state.filter };
		filter.NotaryPeopleId = parseInt(selectedNotaryPeople?.value);
		filter.NotaryPeopleName = selectedNotaryPeople?.label;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });	
	};

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1500);
	};

	private readonly loadOptionsNotaryPeople = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filterNotaryPeople(inputValue));
		}, 1500);
	};	
 
	public readonly handleStatusChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.StatusPowerId = parseInt(option?.key);
		filter.StatusPower = option?.text;
		this.setState({ filter: filter });
	};


	private readonly onClearSearchPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedPerson = { ...this.state.selectedPerson };
		filter.RepresentativeId = null;
		filter.RepresentativeName = null;
		selectedPerson.value=null;
		selectedPerson.label=null;
		this.setState({ filter: filter, selectedPerson: selectedPerson });
	};

	private readonly handlePersonChange = (selected): void => {
		let filter = { ...this.state.filter };
		filter.RepresentativeId = parseInt(selected?.value);
		filter.RepresentativeName = selected?.label;
		this.setState({ filter: filter, selectedPerson: selected });
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.people && this.props.people.length > 0) {
			this.props.people.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};

	private readonly filterNotaryPeople = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.notaryPeopleNames && this.props.notaryPeopleNames.length > 0) {
			this.props.notaryPeopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};

	public readonly handleRevocationDateFromChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.RevocationDateFrom = value;
		this.setState({ filter: filter });
	};

	public readonly handleRevocationDateToChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.RevocationDateTo = value;
		this.setState({ filter: filter });
	};

	private readonly handleCifChange = (value) => {
		let state = { ...this.state };
		state.filter.Cif = value.target.value;
		this.setState(state);
	};
	private readonly handleCifBlur = (value) => {
		let state = { ...this.state };
		state.filter.Cif = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleUniqueCodeChange = (value) => {
		let state = { ...this.state };
		state.filter.UniqueCode = value.target.value;
		this.setState(state);
	};
	private readonly handleUniqueCodeBlur = (value) => {
		let state = { ...this.state };
		state.filter.UniqueCode = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleSubjectChange = (value) => {
		let state = { ...this.state };
		state.filter.SubjectValue = value.target.value;
		this.setState(state);
	};
	private readonly handleSubjectBlur = (value) => {
		let state = { ...this.state };
		state.filter.SubjectValue = value.target.value.toUpperCase();
		this.setState(state);
	};

	public readonly handleCreationDateFromChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.CreationDateFrom = value;
		this.setState({ filter: filter });
	};

	public readonly handleCreationDateToChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.CreationDateTo = value;
		this.setState({ filter: filter });
	};

	private readonly handleBusinessChange = async (event, option): Promise<void> => {
		let filter = { ...this.state.filter };
		filter.TradeId = parseInt(option?.key);
		filter.Trade = option?.text;
		this.setState({ filter: filter });
		let tradeId = option?.key;
		await this.props.getCompanies(tradeId);
	};
	private readonly handleRepresentativeBusinessChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.RepresentativeTradeId = parseInt(option?.key);
		filter.RepresentativeTrade = option?.text;
		this.setState({ filter: filter });
	};
	// private readonly handleCompanyChange = (event, option) => {
	// 	let filter = { ...this.state.filter };
	// 	if(option && option.selected == true){
	// 		filter.CompaniesId.push(option.key);
	// 		filter.CompanyName.push(option.text);
	// 	}else{
	// 		filter.CompaniesId = filter.CompaniesId.filter(e => e !== option.key)
	// 		filter.CompanyName = filter.CompanyName.filter(e => e !== option.text)
	// 	}
	// 	this.setState({ filter: filter });
	// };

	private readonly handleReactSelectCompanyChange = (values) => {

		let filter = { ...this.state.filter };

		filter.CompaniesId.length = 0;
		filter.CompanyName.length = 0;
		filter.selectedCompanies.length = 0;

		if(values!==undefined && values!==null){

			values.forEach((item) => {
				filter.selectedCompanies.push({value:item.value.toString(), label:item.label, disolutionDate:item.disolutionDate, liquidationDate: item.liquidationDate});
				filter.CompaniesId.push(item.value);
				filter.CompanyName.push(item.label);
			})
		}

		this.setState({ filter: filter });
	};

	private readonly handleDepartmentChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.DepartmentId = parseInt(option?.key);
		filter.Department = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handlePowerChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.PowerId = parseInt(option?.key);
		filter.PowerTypeName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleStateChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.StateId = parseInt(option?.key);
		filter.State = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleCountriesChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.CountryId = parseInt(option?.key);
		filter.Country = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleExerciseChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.PowerExerciseId = parseInt(option?.key);
		filter.PowerExerciseName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleProtocolChange = (value) => {
		let state = { ...this.state };
		if (this.getTradeIdRole() !== businessTypeEnum.construccion || value.target.value.match("^\\s*-?[0-9]{0,5}\\s*$")){
			state.filter.Protocol = value.target.value;
			this.setState(state);
		}		
	};
	private readonly handleProtocolBlur = (value) => {
		let state = { ...this.state };
		state.filter.Protocol = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleInscriptionChange = (value) => {
		let state = { ...this.state };
		state.filter.Inscription = value.target.value;
		this.setState(state);
	};
	private readonly handleInscriptionBlur = (value) => {
		let state = { ...this.state };
		state.filter.Inscription = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handleDniChange = (value) => {
		let state = { ...this.state };
		state.filter.Dni = value.target.value;
		this.setState(state);
	};
	private readonly handleDniBlur = (value) => {
		let state = { ...this.state };
		state.filter.Dni = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly handlePassportChange = (value) => {
		let state = { ...this.state };
		state.filter.PassportOthers = value.target.value;
		this.setState(state);
	};
	private readonly handlePassportBlur = (value) => {
		let state = { ...this.state };
		state.filter.PassportOthers = value.target.value.toUpperCase();
		this.setState(state);
	};

	private readonly filterRepresentatives = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getRepresentatives(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterRepresentatives = () => {
		let resetFilter = createEmptyRepresentativeFilter();
		let onlyFinancesFilter = utils.isInRoles(onlyFinanceRolesType);
		resetFilter.PowerId = onlyFinancesFilter ? PowerTypeEnum.financiero : 0;
		resetFilter.PowerTypeName = this.props.language.name.includes(ActiveLanguage.English)
			? this.props.powerTypeNames.find((x) => x.id === resetFilter.PowerId)?.descriptionEnglish
			: this.props.powerTypeNames.find((x) => x.id === resetFilter.PowerId)?.descriptionSpanish;
		
			if (this.props.countries.length === 1) {
			resetFilter.CountryId = this.state.filter.CountryId;
			resetFilter.Country = this.state.filter.Country;
		}	

		this.setState({ filter: resetFilter, selectedPerson: null, selectedNotaryPeople: null }, () =>
			this.props.getRepresentatives(this.state.filter, 1, this.props.itemsPage),
		);
		this.props.onchangeFilter(resetFilter);
	};

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
}
