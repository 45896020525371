import { Icon } from 'office-ui-fabric-react/lib/Icon';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { languages } from '../../../common/const/resources';
import { onlyFinanceRolesType, powersAllowWrite } from '../../../common/const/rolesAssignments';
import { routeUrls } from '../../../common/const/routes';
import { NoResultsComponent } from '../../../common/noResults/noResults';
import { SpinnerContainer } from '../../../common/spinner';
import { BaseName, BaseType, Language, NotaryPeople, Person } from '../../../model';
import { RepresentativeCollection } from '../../../model/Representative';
import { User } from '../../../model/User';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { RepresentativeDetailFilter, RepresentativeFilter } from '../../../model/filters/RepresentativeFilter';
import { UserState } from '../../../reducers/users';
import * as utils from '../../../utils/extensions';
import RepresentativesDataGrid from './grid';
import { RepresentativeSearchComponent } from './search';

export interface IProps {
	representatives: RepresentativeCollection;
	companies: BaseName[];
	departments: BaseType[];
	countries: BaseName[];
	powerTypeNames: BaseName[];
	powerExerciseNames: BaseType[];
	powerNames: BaseType[];
	peopleNames: Person[];
	notaryPeopleNames: NotaryPeople[];
	language: Language;
	authorizationsUsers: Person[];
	user: User;
	currentUser: UserState;
	stateRepresentatives: BaseType[];
	business: BaseType[];
	exerciseRepresentative: BaseType[];
	filter: RepresentativeFilter;

	exportToExcel: (filter: RepresentativeFilter) => Promise<any>;
	getRepresentatives: (filter: RepresentativeFilter, pageNumbers: number, itemsPage: number) => Promise<RepresentativeCollection>;
	getRepresentative: (personId: number) => RepresentativeDetailFilter;
	setRepresentativeDetailFilter: (filter: RepresentativeDetailFilter) => RepresentativeDetailFilter;
	getCompanies: () => Promise<BaseName[]>;
	getPowers: () => Promise<BaseType[]>;
	getPowerTypes: () => Promise<BaseType[]>;
	getPowerExerciseNames: () => Promise<BaseType[]>;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	getNotaryPeopleNames: (filter: string) => Promise<NotaryPeople[]>;
	getCountries: () => Promise<BaseName[]>;
	getBusinessNames: () => Promise<BaseName[]>;
	getDepartments: () => Promise<BaseName[]>;
	deleteRepresentative: (filter: RepresentativeFilter, language: Language, id: number, currentUser: UserState) => Promise<boolean>;
}

export interface IState {
	filter: RepresentativeFilter;
}

export class RepresentativesPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: any) {
		super(props);

		this.state = {
			filter: this.props.filter,
		};
	}
	public async componentDidMount() {
		window.scrollTo(0, 0);
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (this.props.location.state != null) {
			this.props.getRepresentatives(this.props.location.state.RepresentativeFilter, page, this.props.filter.ItemsPage);
		} else {
			this.props.getRepresentatives(this.props.filter, page, this.props.filter.ItemsPage);
		}

		this.props.getCompanies();
		this.props.getPowers();
		this.props.getPowerTypes();
		this.props.getPowerExerciseNames();
		this.props.getCountries();
		this.props.getBusinessNames();
		this.props.getDepartments();
		this.props.getNotaryPeopleNames();
	}

	public componentDidUpdate(prevProps) {
		let oldPage = prevProps.match.params['page'];
		let newPage = this.props.match.params['page'];
		let page = 1;
		if (Number(this.props.match.params['page']) > 0) {
			page = Number(this.props.match.params['page']);
		}
		if (this.props.powerTypeNames !== prevProps.powerTypeNames) {
			if (utils.isInRoles(onlyFinanceRolesType)) {
				let financial = this.props.powerTypeNames.find((x) => x.descriptionEnglish === 'Financial')?.id;
				if (financial !== undefined) {
					let filter = { ...this.state.filter };
					filter.PowerId = financial;
					this.setState({filter: filter},()=>this.props.getRepresentatives(filter, page, this.state.filter.ItemsPage))					
				}
			}
		}

		if(this.props.countries !== prevProps.countries){
			if(this.props.countries.length === 1){
				let netherlands = this.props.countries.find(x => x.descriptionEnglish === "Netherlands")?.id;
				if(netherlands !== undefined){
					let filter = { ...this.state.filter };
					filter.CountryId = netherlands;
					this.setState({ filter: filter }, () => this.props.getRepresentatives(filter, page, this.props.filter.ItemsPage));				}
			}
		}

		if (newPage !== oldPage) {
			this.props.getRepresentatives(this.props.filter, newPage, this.props.filter.ItemsPage);
		}
	}

	public render() {
		//let isReadOnly = utils.isInRoles(onlyFinanceRolesType) || utils.isInRoles(onlyNetherlands);
		let isReadOnly = !utils.isInRoles(powersAllowWrite);
		let powerTypes: BaseType[] = this.props.powerTypeNames ? this.props.powerTypeNames : [];
		let page: number = Number(this.props.match.params['page']);

		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		// let poderesFiltrados = this.props.representatives;
		// console.log(poderesFiltrados);
		// console.log(this.state.filter);



		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='main-container__pageTitle'>
					<h1>{language['representatives']['list']['title']}</h1>
					{!isReadOnly && (
						<Link to='/representatives/new' className='button-addButton'>
							<Icon iconName='Add' />
							<span>{language['representatives']['list']['new-button']}</span>
						</Link>
					)}
				</div>
				<div className='ms-Grid'>
					<RepresentativeSearchComponent
						filter={this.state.filter}
						companies={this.props.companies}
						getCompanies={this.props.getTradeCompanies}
						tradeCompanies={this.props.tradeCompanies}
						powers={this.props.powerNames}
						departments={this.props.departments}
						powerTypeNames={this.props.powerTypeNames}
						powerExerciseNames={this.props.powerExerciseNames}
						getRepresentatives={this.props.getRepresentatives}
						people={this.props.peopleNames}
						onchangeFilter={this.handleFilterChange}
						language={this.props.language}
						representativeUsers={this.props.representativeUsers}
						countries={this.props.countries}
						business={this.props.business}
						getPeopleNames={this.props.getPeopleNames}
						itemsPage={this.state.filter.ItemsPage}
						user={this.props.currentUser}
						notaryPeopleNames={this.props.notaryPeopleNames}
						getNotaryPeopleNames={this.props.getNotaryPeopleNames}
					/>
					{this.props.representatives.list?.length > 0 ? (
						<RepresentativesDataGrid
							powerTypes={powerTypes}
							representatives={this.props.representatives}
							user={this.props.user}
							language={this.props.language}
							setRepresentativeDetailFilter={this.props.setRepresentativeDetailFilter}
							filter={this.state.filter}
							page={page}
							onchangeFilter={this.handleFilterChange}
							exportToExcel={this.props.exportToExcel}
							getRepresentative={this.props.getRepresentative}
							getRepresentatives={this.props.getRepresentatives}
							currentUser={this.props.currentUser}
							deleteRepresentative={this.props.deleteRepresentative}
						/>
					) : (
						<NoResultsComponent language={this.props.language} list={language['representatives']['list']['title']} />
					)}
				</div>
			</>
		);
	}

	public readonly handleFilterChange = (newfilter: RepresentativeFilter): void => {
		this.setState({ filter: newfilter });
		this.props.history.push(routeUrls.representativesPagination + 1);
	};
}
