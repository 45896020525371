import { actionTypes } from '../../../../common/const/actionTypes';
import { RepresentativeApi } from '../../../../api/representatives';
import { RepresentativeFilter } from '../../../../model/filters/RepresentativeFilter';
import { RepresentativeCollection } from '../../../../model/Representative';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';

export const getRepresentativesAction = (filter: RepresentativeFilter, pageNumber: number, itemsPage: number) => (dispatch) => {
	dispatch(httpCallStartAction());
	dispatch(setRepresentativeFilterCompleted(filter));
	RepresentativeApi.FilterAsync(filter, pageNumber, itemsPage).then((content) => {
		dispatch(getRepresentativesCompleted(content));
	});
};
const getRepresentativesCompleted = (representativeCollection: RepresentativeCollection) => ({
	type: actionTypes.GET_REPRESENTATIVES,
	payload: representativeCollection,
	meta: {
		httpEnd: true,
	},
});

const setRepresentativeFilterCompleted = (filter: RepresentativeFilter) => ({
	type: actionTypes.SET_REPRESENTATIVE_FILTER,
	payload: filter,
	meta: {
		httpEnd: true,
	},
});
