import { ComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react/lib/ComboBox';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/components/DatePicker';
import * as React from 'react';
import { languages } from '../../../common/const/resources';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { CollapseFiltersButton } from '../../../common/filters/collapseFiltersButton';
import { SearchPeople } from '../../../common/search/searchPeople';
import {
	BaseType,
	CompanyBase,
	DeedCollection,
	DeedTypeSubType,
	Language,
	NotaryPeople,
	SearchSelectModel,
	UserState
} from '../../../model/';
import { BaseName } from '../../../model/Base';
import { DeedType } from '../../../model/DeedType';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { DeedFilter, createEmptyDeedFilter } from '../../../model/filters';
import { businessTypeEnum } from '../../../utils/enums/businessTypeEnum';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';

export interface IProps {
	getDeeds: (filter: DeedFilter, pageNumbers: number, itemsPage: number) => Promise<DeedCollection>;
	getNotaryPeopleNames: (filter: string) => void;
	getCompanies: (tradeId?: number) => Promise<BaseName[]>;
	filter: DeedFilter;
	companies: CompanyBase[];
	tradeCompanies: CompanyBase[];
	deedTypeSubTypes: DeedTypeSubType[];
	deedTypePrimaries: DeedType[];
	onchangeFilter: any;
	language: Language;
	itemsPage: number;
	business: BaseType[];
	originals: BaseType[];
	notaryPeopleNames: NotaryPeople[];
	user: UserState;
}

export interface IState {
	filter: DeedFilter;
	collapsed: boolean;
	selectedNotaryPeople: any;
	showDissolved: boolean;
}

export class DeedsSearchComponent extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			collapsed: false,
			filter: createEmptyDeedFilter(),
			selectedNotaryPeople: null,
			showDissolved: true,
		};
	}

	private getTradeIdRole(): number {
		switch (this.props.user.currentUser.roles.sort()[0]) {
			case userRoles.ajAeropuertos:
			case userRoles.ajAeropuertosH:
				return businessTypeEnum.aeropuertos;
			case userRoles.ajCintra:
			case userRoles.ajCintraH:
				return businessTypeEnum.cintra;
			case userRoles.ajConstruccion:
			case userRoles.ajConstruccionH:
				return businessTypeEnum.construccion;
			case userRoles.ajCorporacion:
			case userRoles.ajCorporacionH:
				return businessTypeEnum.corporacion;
			case userRoles.ajFeim:
			case userRoles.ajFeimH:
				return businessTypeEnum.feim;
			case userRoles.admin:
				return 0;
		}
	}

	public render() {
		let language = languages.languageES;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}

		let calendarStrings: IDatePickerStrings = language['calendar'];

		let companies: IComboBoxOption[] = [];
		companies.push({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		companies.push({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		if(this.state.filter.TradeId <= 0){
			this.props.companies.forEach((item) => {
				companies.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}	else {
			this.props.tradeCompanies.forEach((item) => {
				companies.push({
					key: item.id,
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null,
				});
			});
		}
		

		let deedTypePrimariesCombo: IComboBoxOption[] = [];
		
		this.props.deedTypePrimaries.forEach((item) => {
			deedTypePrimariesCombo.push({
				key: item.id,
				text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
			});
		});
		deedTypePrimariesCombo.sort((a, b) => a.text.localeCompare(b.text))
		deedTypePrimariesCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		deedTypePrimariesCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let deedTypesSubTypesCombo: IComboBoxOption[] = [];		
		let deedType = this.props.deedTypeSubTypes.find((item) => item.id === this.state.filter.DeedTypeId);
		if (deedType != null) {
			if (deedType.listSubTypes != null) {
				deedType.listSubTypes.forEach((item) => {
					deedTypesSubTypesCombo.push({
						key: item.id,
						text: language === languages.languageES ? item.descriptionSpanish : item.descriptionEnglish,
					});
				});
			}
		}
		deedTypesSubTypesCombo.sort((a, b) => a.text.localeCompare(b.text))
		deedTypesSubTypesCombo.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		deedTypesSubTypesCombo.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			business.sort((a, b) => a.text.localeCompare(b.text))
			business.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
			business.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
		}

		let originals: IComboBoxOption[] = [];
		if (this.props.originals) {
			this.props.originals.forEach((item) => {
				originals.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
			originals.sort((a, b) => a.text.localeCompare(b.text))
			originals.unshift({ key: -1, text: language['generic']['form']['combo-box']['default'] });
			originals.unshift({ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider });
		}

		let affectsCapitalCombo: IComboBoxOption[] = [
			{ key: -1, text: language['generic']['both'] },
			{ key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
			{ key: 1, text: language['generic']['yes'] },
			{ key: 0, text: language['generic']['no'] },
		];

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>
		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companies.sort((a, b) => a.text.localeCompare(b.text));
		// sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'],
		// 	itemType: DropdownMenuItemType.Header,
		// });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });

		let selectedCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = selectedCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });

		return (
			<>
				<div className={this.state.collapsed ? 'ms-Grid-row filter-collapse' : 'ms-Grid-row filter'}>
					<div className={this.state.collapsed ? 'ms-Grid-row filter_header-collapse' : 'ms-Grid-row filter_header'}>
						<div className='ms-Grid-col ms-xl6'>
							{this.state.collapsed
								? language['generic']['list']['filter']['collapsedTitle']
								: language['generic']['list']['filter']['btnTitle']}
						</div>
						<div className='ms-Grid-col ms-xl6'>
							<CollapseFiltersButton onClick={this.handleFiltersCollapse} collapsed={this.state.collapsed} />
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? 'none' : '' }}>
						<div className='ms-Grid-row flex-option'>
							{/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['society']['title']}</p>
								<VirtualizedComboBox
									autoComplete='on'
									allowFreeform={false}
									options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
									placeholder={language['deeds']['list']['filter']['options']['society']['placeholder']}
									onChange={this.handleCompanyChange}
									selectedKey={this.state.filter.CompaniesId}
									onRenderOption={onRenderOption}
									multiSelect={true}
									
									
								/>
							</div> */}
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['society']['title']}</p>
								<CustomSelect
									options={options}
									handleCustomSelectChange={this.handleReactSelectCompanyChange}
									showDissolved={this.state.showDissolved}
									handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
									language={language}
									showDissolutionDate={true}
									selectedValues={this.state.filter.selectedCompanies}
									isMultiSelection={true}
									isDisabled={false}
									placeholder={language['deeds']['list']['filter']['options']['society']['placeholder']}
								/>
							</div>							
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['uniqueCode']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.UniqueCode}
									onChange={this.handleUniqueCodeChange}
									onBlur={this.handleUniqueCodeBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['deed-type']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={deedTypePrimariesCombo}
									placeholder={language['deeds']['list']['filter']['options']['deed-type']['placeholder']}
									onChange={this.handleDeedTypeChange}
									selectedKey={this.state.filter.DeedTypeId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['deed-subtype']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={deedTypesSubTypesCombo}
									placeholder={language['deeds']['list']['filter']['options']['deed-subtype']['placeholder']}
									onChange={this.handleDeedSubTypeChange}
									selectedKey={this.state.filter.DeedSubtypeId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md8 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['date-document']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										firstDayOfWeek={language == languages.languageEN ? DayOfWeek.Sunday : DayOfWeek.Monday}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel='Fecha de creación desde'
										strings={calendarStrings}
										value={this.state.filter.DocumentFromDate}
										maxDate={this.state.filter.DocumentToDate}
										onSelectDate={this.handleFromDateChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel='Fecha de creación hasta'
										strings={calendarStrings}
										value={this.state.filter.DocumentToDate}
										minDate={this.state.filter.DocumentFromDate}
										onSelectDate={this.handleToDateChange}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<SearchPeople
									title={language['deeds']['list']['filter']['options']['notaryPeople']['title']}
									placeholder={language['deeds']['list']['filter']['options']['notaryPeople']['placeholder']}
									language={this.props.language}
									selectedOption={this.state.selectedNotaryPeople}
									loadOptions={this.loadOptions}
									handleNameChange={this.handleNotaryPeopleNameChange}
									getUsers={this.props.getNotaryPeopleNames}
									onClearValue={this.onClearSearchPeople}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['protocol']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.ProtocolNumber}
									onChange={this.handleProtocolChange}
									onBlur={this.handleProtocolBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['inscription']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Inscription}
									onChange={this.handleInscriptionChange}
									onBlur={this.handleInscriptionBlur}
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['date-inscription']['title']}</p>
								<div className='range-picker'>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										placeholder={language['generic']['form']['fields']['date']['from']}
										ariaLabel='Fecha de inscripción desde'
										strings={calendarStrings}
										value={this.state.filter.InscriptionFromDate}
										maxDate={this.state.filter.InscriptionToDate}
										onSelectDate={this.handleFromInscriptionDateChange}
									/>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										className={''}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										placeholder={language['generic']['form']['fields']['date']['to']}
										ariaLabel='Fecha de inscripción hasta'
										strings={calendarStrings}
										value={this.state.filter.InscriptionToDate}
										minDate={this.state.filter.InscriptionFromDate}
										onSelectDate={this.handleToInscriptionDateChange}
									/>
								</div>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['subject']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Subject}
									onChange={this.handleSubjectChange}
									onBlur={this.handleSubjectBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['capital']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={affectsCapitalCombo.sort((a, b) => a.text.localeCompare(b.text))}
									placeholder={language['deeds']['list']['filter']['options']['capital']['title']}
									onChange={this.handleAffectsCapitalChange}
									selectedKey={this.state.filter.AffectsCapital}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['trade']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={business}
									placeholder={language['deeds']['list']['filter']['options']['trade']['placeholder']}
									onChange={this.handleBusinessChange}
									selectedKey={this.state.filter.TradeId}
									useComboBoxAsMenuWidth
								/>
							</div>
						</div>
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['original']['title']}</p>
								<ComboBox
									allowFreeform
									autoComplete='on'
									options={originals}
									placeholder={language['deeds']['list']['filter']['options']['original']['placeholder']}
									onChange={this.handleOriginalChange}
									selectedKey={this.state.filter.OriginalId}
									useComboBoxAsMenuWidth
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['copy']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Copy}
									onChange={this.handleCopyChange}
									onBlur={this.handleCopyBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['location']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.LocationDeed}
									onChange={this.handleLocationChange}
									onBlur={this.handleLocationBlur}
								/>
							</div>
							<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl3 content_edit_block_filter'>
								<p>{language['deeds']['list']['filter']['options']['simple']['title']}</p>
								<TextField
									type='text'
									value={this.state.filter.Simple}
									onChange={this.handleSimpleChange}
									onBlur={this.handleSimpleBlur}
								/>
							</div>
						</div>
						<hr />
						<div className='ms-Grid-row flex-option'>
							<div className='ms-Grid-col ms-xl12 main-container__content_colButtons'>
								<button type='button' className='filter_button-clean' onClick={this.cleanFilterDeeds}>
									{language['generic']['list']['filter']['btnClean']}
								</button>
								<button type='button' className='filter_button-apply' onClick={this.filterDeeds}>
									{language['generic']['list']['filter']['btnApply']}
								</button>
							</div>
						</div>
					</div>
					<div style={{ display: this.state.collapsed ? '' : 'none' }}>
						<div className='filter_collapse'>
							<p style={{ display: this.state.filter.CompaniesId.length > 0 ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['society']['title']}</span>
								<span className='value'>{this.state.filter.CompaniesName?.join(", ")}</span>
							</p>
							<p style={{ display: this.state.filter.UniqueCode !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['uniqueCode']['title']}</span>
								<span className='value'>{this.state.filter.UniqueCode}</span>
							</p>
							<p style={{ display: this.state.filter.DeedTypeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['deed-type']['title']}</span>
								<span className='value'>{this.state.filter.DeedTypeName}</span>
							</p>
							<p style={{ display: this.state.filter.DeedSubtypeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['deed-subtype']['title']}</span>
								<span className='value'>{this.state.filter.DeedSubtypeName}</span>
							</p>
							<p
								style={{
									display:
										this.state.filter.DocumentFromDate != null || this.state.filter.DocumentToDate != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>{language['deeds']['list']['filter']['options']['date-document']['title']}</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.filter.DocumentFromDate,
										this.state.filter.DocumentToDate,
										this.props.language,
									)}
								</span>
							</p>
							<p style={{ display: this.state.filter.NotaryName !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['notary']['title']}</span>
								<span className='value'>{this.state.filter.NotaryName}</span>
							</p>
							<p style={{ display: this.state.filter.ProtocolNumber !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['protocol']['title']}</span>
								<span className='value'>{this.state.filter.ProtocolNumber}</span>
							</p>
							<p style={{ display: this.state.filter.Inscription !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['inscription']['title']}</span>
								<span className='value'>{this.state.filter.Inscription}</span>
							</p>
							<p
								style={{
									display:
										this.state.filter.InscriptionFromDate != null || this.state.filter.InscriptionToDate != null
											? ''
											: 'none',
								}}
							>
								<span className='key'>{language['deeds']['list']['filter']['options']['date-inscription']['title']}</span>
								<span className='value'>
									{utils.dateRangeToString(
										this.state.filter.InscriptionFromDate,
										this.state.filter.InscriptionToDate,
										this.props.language,
									)}
								</span>
							</p>
							<p style={{ display: this.state.filter.Subject !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['subject']['title']}</span>
								<span className='value'>{this.state.filter.Subject}</span>
							</p>
							<p>
								<span className='key'>{language['deeds']['list']['filter']['options']['capital']['title']}</span>
								{this.state.filter.AffectsCapital === -1 ? (
									<span className='value'>
										{this.state.filter.AffectsCapital === -1 ? language['generic']['both'] : ''}
									</span>
								) : (
									<span className='value'>
										{this.state.filter.AffectsCapital === 1 ? language['generic']['yes'] : language['generic']['no']}
									</span>
								)}
							</p>
							<p style={{ display: this.state.filter.TradeId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['trade']['title']}</span>
								<span className='value'>{this.state.filter.Trade}</span>
							</p>
							<p style={{ display: this.state.filter.OriginalId !== 0 ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['original']['title']}</span>
								<span className='value'>{this.state.filter.OriginalName}</span>
							</p>
							<p style={{ display: this.state.filter.Copy !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['copy']['title']}</span>
								<span className='value'>{this.state.filter.Copy}</span>
							</p>
							<p style={{ display: this.state.filter.Simple !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['simple']['title']}</span>
								<span className='value'>{this.state.filter.Simple}</span>
							</p>
							<p style={{ display: this.state.filter.LocationDeed !== '' ? '' : 'none' }}>
								<span className='key'>{language['deeds']['list']['filter']['options']['location']['title']}</span>
								<span className='value'>{this.state.filter.LocationDeed}</span>
							</p>
							<p
								style={{
									display:
										this.state.filter.NotaryPeopleId != null && this.state.filter.NotaryPeopleId !== 0 ? '' : 'none',
								}}
							>
								<span className='key'>{language['deeds']['list']['filter']['options']['notaryPeople']['title']}</span>
								<span className='value'>{this.state.filter.NotaryPeopleName}</span>
							</p>
						</div>
					</div>
				</div>
			</>
		);
	}

	componentDidMount() {
		let selectedNotaryPeople = null;
		if (this.props.filter.NotaryPeopleId > 0) {
			selectedNotaryPeople = { value: this.props.filter.NotaryPeopleId, label: this.props.filter.NotaryPeopleName };
		}

		this.setState({ filter: this.props.filter, selectedNotaryPeople: selectedNotaryPeople });
	}

	private readonly loadOptions = (inputValue, callback) => {
		setTimeout(() => {
			callback(this.filter(inputValue));
		}, 1500);
	};

	private readonly filter = (inputValue: string) => {
		let users: SearchSelectModel[] = [];
		if (inputValue.length >= 4 && this.props.notaryPeopleNames && this.props.notaryPeopleNames.length > 0) {
			this.props.notaryPeopleNames.forEach((item) => {
				users.push({ value: item.id, label: `${item.name}` });
			});
		}

		return users;
	};

	private readonly onClearSearchPeople = (): void => {
		let filter = { ...this.state.filter };
		let selectedNotaryPeople = { ...this.state.selectedNotaryPeople };
		filter.NotaryPeopleId = null;
		filter.NotaryPeopleName = null;
		selectedNotaryPeople.value = null;
		selectedNotaryPeople.label = null;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });
	};

	private readonly handleNotaryPeopleNameChange = (selectedNotaryPeople) => {
		let filter = { ...this.state.filter };
		filter.NotaryPeopleId = parseInt(selectedNotaryPeople?.value);
		filter.NotaryPeopleName = selectedNotaryPeople?.label;
		this.setState({ filter: filter, selectedNotaryPeople: selectedNotaryPeople });
	};

	private readonly handleOriginalChange = (event, option) => {
		let filter = { ...this.state.filter };
		filter.OriginalId = parseInt(option?.key);
		filter.OriginalName = option?.text;
		this.setState({ filter: filter });
	};

	private readonly handleCopyChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Copy = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleCopyBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Copy = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleSimpleChange = (value) => {
		let filter = { ...this.state.filter };
		filter.Simple = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleSimpleBlur = (value) => {
		let filter = { ...this.state.filter };
		filter.Simple = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	// private readonly handleCompanyChange = (event, option): void => {
	// 	let filter = { ...this.state.filter };
	// 	if (option.selected == true) {
	// 		filter.CompaniesId.push(option.key);
	// 		filter.CompaniesName.push(option.text);
	// 	} else {
	// 		filter.CompaniesId = filter.CompaniesId.filter((e) => e !== option.key);
	// 		filter.CompaniesName = filter.CompaniesName.filter((e) => e !== option.text);
	// 	}
	// 	this.setState({ filter: filter });
	// };

	private readonly handleReactSelectCompanyChange = (values) => {

		let filter = { ...this.state.filter };

		filter.CompaniesId.length = 0;
		filter.CompaniesName.length = 0;
		filter.selectedCompanies.length = 0;

		if(values!==undefined && values!==null){

			values.forEach((item) => {
				filter.selectedCompanies.push({value:item.value.toString(), label:item.label, disolutionDate:item.disolutionDate, liquidationDate: item.liquidationDate});
				filter.CompaniesId.push(item.value);
				filter.CompaniesName.push(item.label);
			})
		}

		this.setState({ filter: filter });
	};	

	private readonly handleDeedTypeChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.DeedTypeId = parseInt(option?.key);
		filter.DeedTypeName = option?.text;
		filter.DeedSubtypeName = '';
		filter.DeedSubtypeId = 0;
		this.setState({ filter: filter });
	};

	private readonly handleDeedSubTypeChange = (event, option): void => {
		let filter = { ...this.state.filter };
		let newSubtypeId = parseInt(option?.key);
		filter.DeedSubtypeName = option?.text;
		filter.DeedSubtypeId = newSubtypeId === 0 ? null : newSubtypeId;
		this.setState({ filter: filter });
	};

	private readonly handleBusinessChange = async (event, option): Promise<void> => {
		let filter = { ...this.state.filter };
		filter.TradeId = parseInt(option?.key);
		filter.Trade = option?.text;
		this.setState({ filter: filter });
		let tradeId = option?.key;
		await this.props.getCompanies(tradeId);
	};

	public readonly handleFromDateChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.DocumentFromDate = value;
		this.setState({ filter: filter });
	};

	public readonly handleToDateChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.DocumentToDate = value;
		this.setState({ filter: filter });
	};

	public readonly handleFromInscriptionDateChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.InscriptionFromDate = value;
		this.setState({ filter: filter });
	};

	public readonly handleToInscriptionDateChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.InscriptionToDate = value;
		this.setState({ filter: filter });
	};

	public readonly handleInscriptionChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.Inscription = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleInscriptionBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.Inscription = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleSubjectChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.Subject = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleSubjectBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.Subject = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleNotaryChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.NotaryName = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleNotaryBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.NotaryName = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleUniqueCodeChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.UniqueCode = value.target.value;
		this.setState({ filter: filter });
	};
	public readonly handleUniqueCodeBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.UniqueCode = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	public readonly handleProtocolChange = (value): void => {
		let filter = { ...this.state.filter };
		if (this.getTradeIdRole() !== businessTypeEnum.construccion || value.target.value.match("^\\s*-?[0-9]{0,5}\\s*$")){
			filter.ProtocolNumber = value.target.value;
			this.setState({ filter: filter });
		}
	};
	public readonly handleProtocolBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.ProtocolNumber = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleLocationChange = (value): void => {
		let filter = { ...this.state.filter };
		filter.LocationDeed = value.target.value;
		this.setState({ filter: filter });
	};
	private readonly handleLocationBlur = (value): void => {
		let filter = { ...this.state.filter };
		filter.LocationDeed = value.target.value.toUpperCase();
		this.setState({ filter: filter });
	};

	private readonly handleAffectsCapitalChange = (event, option): void => {
		let filter = { ...this.state.filter };
		filter.AffectsCapital = option?.key;
		this.setState({ filter: filter });
	};

	private readonly filterDeeds = () => {
		let filter = { ...this.state.filter };
		this.setState({ collapsed: true });
		this.props.onchangeFilter(filter);
		this.props.getDeeds(filter, 1, this.props.itemsPage);
	};

	private readonly cleanFilterDeeds = () => {
		let resetFilter = createEmptyDeedFilter();

		this.setState({ filter: resetFilter, selectedNotaryPeople: null });
		this.props.onchangeFilter(resetFilter);
		this.props.getDeeds(resetFilter, 1, this.props.itemsPage);
	};

	private readonly handleFiltersCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	};
}
