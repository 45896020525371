import * as React from 'react';
import * as utils from '../../../utils/extensions';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
	Language,
	RecordCompanyCustom,
	RecordCompanyDetails,
	StockholderCompany,
	AdminBodyCompany,
	UserInfo,
} from '../../../model';
import { createEmptyRecordCompanyDetailFilter, RecordCompanyDetailFilter } from '../../../model/filters/';
import { SpinnerContainer } from '../../../common/spinner';
import { languages } from '../../../common/const/resources';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { Icon } from 'office-ui-fabric-react';
import '../../../styles/pages/corporateDetails.scss';
import { AdminBodyGrid } from './grids/adminBodyGrid';
import { ActualStockholdersGrid } from './grids/actualStockholdersGrid';
import { HistoricallStockholdersGrid } from './grids/historicalStockholdersGrid';
import { stringIsNullOrEmpty } from '@pnp/common';
import { TextField } from 'office-ui-fabric-react';
import { DialogComponent } from '../../../common/dialog/dialogComponent';
import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import { GenericApi } from '../../../api/generic';
import { MeApi } from '../../../api/me';

export interface IProps {
	recordCompany: RecordCompanyCustom;
	language: Language;
	getRecordCompany: (filter: RecordCompanyDetailFilter) => Promise<RecordCompanyCustom>;
	exportAdminBodyToExcel: (companyId: number[]) => Promise<any>;
	exportActualStockholdersToExcel: (companyId: number[]) => Promise<any>;
	exportHistoricalStockholdersToExcel: (companyId: number[]) => Promise<any>;
	savePowerRevokeAllCorporate: (companyId: number, revokeMsg: string, language: Language, currentUser: UserInfo) => Promise<number>;
}

export interface IState {
	filter: RecordCompanyDetailFilter;
	previousPage: string;
	showModal: boolean;
	revokeMsg: string;
	protocolNumber: string;
	errorMessages: string[];
	visibleError: boolean;
}

export class RecordCompanyDetailPage extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);
		this.state = {
			filter: createEmptyRecordCompanyDetailFilter(),
			previousPage: new URL(window.location.href).searchParams.get('previousPage'),
			showModal: false,
			revokeMsg: '',
			protocolNumber: '',
			errorMessages: [],
			visibleError: false,
		};
	}

	public componentDidMount() {
		if (this.props.match.params['id'] != null) {
			let filterAux: RecordCompanyDetailFilter = this.state.filter;
			filterAux.CompaniesId = this.props.match.params['id'];
			this.setState({
				filter: filterAux,
			});
			this.props.getRecordCompany(filterAux);
		}
	}

	public render() {
		let language = languages.languageES;
		let isAdmin = utils.isInRole(userRoles.adminMaster) || utils.isInRole(userRoles.admin);
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		}
		let companyDetails: RecordCompanyDetails = this.props.recordCompany.companyDetails;
		let trade: string = '';
		let country: string = '';
		let positions: AdminBodyCompany[] = [];
		let actualStockholders: StockholderCompany[] = [];
		let historicalStockholders: StockholderCompany[] = [];
		if (companyDetails != null) {
			trade = language === languages.languageES ? companyDetails.tradeSpanish : companyDetails.tradeEnglish;
			country = language === languages.languageES ? companyDetails.countrySpanish : companyDetails.countryEnglish;
			positions = companyDetails.positionCompany ? companyDetails.positionCompany : [];
			actualStockholders = companyDetails.stockholderCompany ? companyDetails.stockholderCompany : [];
			historicalStockholders = companyDetails.historicalStockholderCompany ? companyDetails.historicalStockholderCompany : [];
		}
		let validationErrors = this.state.errorMessages?.map((item: string) => {
			return <MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />;
		});

		return (
			<>
				<SpinnerContainer message='Cargando...' />
				<div className='main-container__pageTitle detail_title'>
					<span onClick={this.goBack} className='back_arrow'>
						<Icon iconName='ChromeBack' />
					</span>
					<h1>{language['corporate']['detail']['return-button']}</h1>
				</div>
				<div className='ms-Grid'>
					<div className='ms-Grid-row'>
						<div className='ms-Grid-col'>
							<span className='societyNameFile'>{companyDetails?.name ? companyDetails.name : '-'}</span>
						</div>
					</div>
					<div className='ms-Grid-row flex-option'>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl4 separator_line'>
							<div className='detailsDataTitle'>
								<span>{language['corporate']['detail']['fields']['general-data']['title']}</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['cif']}</span>
								<span className='value'>
									<b>
										{companyDetails?.cif
											? companyDetails.cif
											: companyDetails?.cifEuropeo
											? companyDetails.cifEuropeo
											: '-'}{' '}
									</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['business-partner']}</span>
								<span className='value'>
									<b>{companyDetails?.interlocutor ? companyDetails.interlocutor : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['company-name']}</span>
								<span className='value'>
									<b>{companyDetails?.name ? companyDetails.name : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['trade']}</span>
								<span className='value'>
									<b>{trade ? trade : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['tax-id']}</span>
								<span className='value'>
									<b>{companyDetails?.cif ? companyDetails.cif : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['community-tax-id']}</span>
								<span className='value'>
									<b>{companyDetails?.cifEuropeo ? companyDetails.cifEuropeo : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['ute']}</span>
								<span className='value'>
									<b>
										{companyDetails?.ute
											? language['generic']['yes'].toUpperCase()
											: language['generic']['no'].toUpperCase()}
									</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['general-data']['social-capital']}</span>
								<span className='value'>
									<b>{companyDetails?.socialCapital ? companyDetails.socialCapital : '-'}</b>
								</span>
							</div>
						</div>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl4 separator_line'>
							<div className='detailsDataTitle'>
								<span>{language['corporate']['detail']['fields']['location']['title']}</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['social-address']}</span>
								<span className='value'>
									<b>{companyDetails?.socialAddress ? companyDetails.socialAddress : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['country']}</span>
								<span className='value'>
									<b>{country ? country : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['town']}</span>
								<span className='value'>
									<b>{companyDetails?.location ? companyDetails.location : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['address']}</span>
								<span className='value'>
									<b>{companyDetails?.address ? companyDetails.address : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['number']}</span>
								<span className='value'>
									<b>{companyDetails?.number ? companyDetails.number : '-'}</b>
								</span>
							</div>
							<div className='personal_data'>
								<span className='key'>{language['corporate']['detail']['fields']['location']['postal-code']}</span>
								<span className='value'>
									<b>{companyDetails?.postalCode ? companyDetails.postalCode : '-'}</b>
								</span>
							</div>
						</div>
						<div className='ms-Grid-col ms-sm12 ms-md6 ms-xl4'>
							<div className='detailsDataTitle'>
								<span>{language['corporate']['detail']['fields']['constitution-date']['title']}</span>
							</div>
							<div className='personal_data'>
								<span className='text'>
									<b>
										{companyDetails.constitutionDate ? utils.getParsedLocaleDate(companyDetails.constitutionDate) : ''}
									</b>
								</span>
							</div>
							{/*<div style={{marginTop:'150px'}}>
								 <MessageBarComponent
										Message={language['generic']['messages']['required-subject']}
										Type={MessageBarType.error}
										MessageVisible={this.state.visibleError}
										close={null}
								/>
								<MessageBarComponent
										Message={language['generic']['messages']['required-protocol-number']}
										Type={MessageBarType.error}
										MessageVisible={this.state.visibleError}
										close={null} 
										/>
							</div> */}
						</div>
						<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
							<p>{language['corporate']['detail']['fields']['revoke-all-powers']['subject']}</p>
							<TextField
								value={utils.isNullOrEmpty(this.state.revokeMsg) ? '' : this.state.revokeMsg}
								//value = {utils.isNullOrEmpty(this.state.company.revokeMsg) ? '' : this.state.company.revokeMsg}
								onChange={this.handleMessageChange}
								//onBlur={this.handleSubjectBlur}
								//disabled={isReadOnly}
								maxLength={255}
								multiline
							/>
							<div className='content_edit_block_filter ms-Grid-col ms-sm12 ms-xl4'></div>
							<p>{language['corporate']['detail']['fields']['revoke-all-powers']['protocol']}</p>
							<TextField
								type='text'
								className=' content_edit_block_filter-inner'
								//value = {utils.isNullOrEmpty(this.state.deed.notaryData?.protocolNumber) ? '' : this.state.deed.notaryData?.protocolNumber}
								onChange={this.handleProtocolChange}
								//onBlur={this.handleProtocolNumberBlur}
								disabled={this.props.isReadOnly}
								maxLength={5}
							/>
							<hr />
							{validationErrors}
							{isAdmin && (
								<button
									type='button'
									className='button-primary'
									onClick={() => this.saveRevoke()}
									style={{ marginRight: '0' }}
								>
									{language['corporate']['detail']['fields']['revoke-all-powers']['button-revoke']}
								</button>
							)}
						</div>
						<DialogComponent
							showModal={this.state.showModal}
							TitleDialog={language['generic']['dialog']['title']}
							QuestionDialog={language['generic']['dialog']['question-go']}
							onSuccess={() => {
								this.handleUpdateRevoke();
							}}
							onFail={() => this.setState({ showModal: false })}
							CancelTitle={language['generic']['dialog']['cancel']}
							SuccessTitle={language['generic']['dialog']['confirm-go']}
						/>
					</div>
					{positions.length > 0 && (
						<AdminBodyGrid
							language={this.props.language}
							positions={positions}
							totalRows={companyDetails.positionCompanyTotalNumberRows}
							filter={this.state.filter}
							getRecordCompany={this.props.getRecordCompany}
							exportToExcel={this.props.exportAdminBodyToExcel}
						/>
					)}
					{actualStockholders.length > 0 && (
						<ActualStockholdersGrid
							language={this.props.language}
							stockholders={actualStockholders}
							totalRows={companyDetails.stockholderCompanyTotalNumberRows}
							filter={this.state.filter}
							getRecordCompany={this.props.getRecordCompany}
							exportToExcel={this.props.exportActualStockholdersToExcel}
						/>
					)}
					{historicalStockholders.length > 0 && (
						<HistoricallStockholdersGrid
							language={this.props.language}
							stockholders={historicalStockholders}
							totalRows={companyDetails.historicalStockholderCompanyTotalNumberRows}
							filter={this.state.filter}
							getRecordCompany={this.props.getRecordCompany}
							exportToExcel={this.props.exportHistoricalStockholdersToExcel}
						/>
					)}
				</div>
			</>
		);
	}

	public readonly goBack = () => {
		this.props.history.push(this.state.previousPage);
	};

	private getConstitutionTemplate(rc: RecordCompanyDetails, language) {
		let notaryDate: string = rc.notaryDate ? utils.getParsedLocaleDate(rc.notaryDate) : '';
		if (
			stringIsNullOrEmpty(rc.notary) ||
			stringIsNullOrEmpty(rc.protocolNumber) ||
			stringIsNullOrEmpty(rc.notary) ||
			notaryDate === '1/1/1'
		) {
			return language['corporate']['detail']['no-data'];
		}

		return (
			language['corporate']['detail']['fields']['constitution-date']['first-part'] +
			rc.notary +
			language['corporate']['detail']['fields']['constitution-date']['second-part'] +
			notaryDate +
			language['corporate']['detail']['fields']['constitution-date']['third-part'] +
			rc.protocolNumber +
			language['corporate']['detail']['fields']['constitution-date']['fourth-part']
		);
	}

	private getRegistralDataTemplate(rc: RecordCompanyDetails, language) {
		let inscriptionDate: string = rc.inscriptionDate ? utils.getParsedLocaleDate(rc.inscriptionDate) : '';
		if (
			stringIsNullOrEmpty(rc.merchandRecord) ||
			stringIsNullOrEmpty(rc.volume) ||
			stringIsNullOrEmpty(rc.section) ||
			stringIsNullOrEmpty(rc.folio) ||
			stringIsNullOrEmpty(rc.sheet) ||
			stringIsNullOrEmpty(inscriptionDate) ||
			inscriptionDate === '1/1/1'
		) {
			return language['corporate']['detail']['no-data'];
		}

		return (
			language['corporate']['detail']['fields']['registral-data']['first-part'] +
			rc.merchandRecord +
			language['corporate']['detail']['fields']['registral-data']['second-part'] +
			rc.volume +
			language['corporate']['detail']['fields']['registral-data']['third-part'] +
			rc.section +
			language['corporate']['detail']['fields']['registral-data']['fourth-part'] +
			rc.folio +
			language['corporate']['detail']['fields']['registral-data']['fifth-part'] +
			rc.sheet +
			language['corporate']['detail']['fields']['registral-data']['sixth-part'] +
			inscriptionDate +
			'.'
		);
	}

	private readonly saveRevoke = (): void => {
		if (this.validateRevoke()) {
			this.setState({ showModal: true });
		}
	};

	private readonly validateRevoke = () => {
		let errorMessages: string[] = [];
		let language = languages.languageES;
		let visibleError = false;
		if (this.props.language.name.includes(ActiveLanguage.English)) {
			language = languages.languageEN;
		} else {
			language = languages.languageES;
		}

		let hasSubject = this.state.revokeMsg.length > 0;

		if (!hasSubject) {
			errorMessages.push(language['generic']['messages']['required-subject']);
		}

		visibleError = !hasSubject;
		this.setState({ errorMessages, visibleError: visibleError });

		if (hasSubject) {
			return true;
		}

		return false;
	};

	public readonly handleUpdateRevoke = () => {
		MeApi.GetUserInfo().then((result)=>{
			this.props.savePowerRevokeAllCorporate(
				this.props.recordCompany.companyDetails.id,
				this.state.revokeMsg,
				this.state.protocolNumber,
				this.props.language,
				result
			);
		})

	};

	public readonly handleMessageChange = (event) => {
		const newMessage = event?.target.value;
		this.setState({ revokeMsg: newMessage });
	};

	public readonly handleProtocolChange = (event) => {
		const newProtocol = event?.target.value;
		this.setState({ protocolNumber: newProtocol });
	};
}
